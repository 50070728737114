import { create } from 'zustand'
import { createJSONStorage, devtools, persist } from 'zustand/middleware'
import { Handler } from '@digital-magic/ts-common-utils'
import {
  FilesFormStep,
  InitialFormStep,
  SummaryStep,
  VehicleFormStep,
  VehicleWizardDataStep,
  VehicleWizardFilesStep,
  VehicleWizardInitialStep,
  VehicleWizardSummaryStep
} from './types'
import { VehicleInitialFormValues } from '@forms/VehicleInitialForm/types'
import { FormPhotos, FormVehicleRequest, VehicleMntResponse } from '@api/endpoints/forms'
import { FormId } from '@api/endpoints'

export const VehicleCommonStepsList = [InitialFormStep, VehicleFormStep, FilesFormStep, SummaryStep] as const

export type VehicleWizardCommonState =
  | VehicleWizardInitialStep
  | VehicleWizardDataStep
  | VehicleWizardFilesStep
  | VehicleWizardSummaryStep

const vehicleWizardCommonStateInitial: VehicleWizardCommonState = {
  activeStep: InitialFormStep,
  data: {}
}

export type VehicleWizardCommonStateActions = Readonly<{
  switchStepToData: Handler<Pick<VehicleInitialFormValues, 'carNumber' | 'phoneNumber'> & VehicleMntResponse>
  switchStepToFiles: Handler<FormVehicleRequest>
  switchStepToSummary: Handler<FormPhotos>
  reset: Handler<void>
}>

const buildDataStep = ({
  formUuid,
  phoneNumber,
  carNumber,
  ...data
}: Pick<VehicleInitialFormValues, 'carNumber' | 'phoneNumber'> & VehicleMntResponse): VehicleWizardDataStep => ({
  activeStep: VehicleFormStep,
  data: {
    carNumber: carNumber,
    mark: data.mntData?.mark,
    model: data.mntData?.model,
    location: undefined,
    requestedPrice: undefined,
    mileage: undefined,
    fullName: undefined,
    email: undefined
  },
  formId: formUuid,
  carNumber,
  phoneNumber
})

const buildFilesStep = (formId: FormId, data: FormVehicleRequest): VehicleWizardFilesStep => ({
  activeStep: FilesFormStep,
  data: {
    imageIds: []
  },
  formId,
  carNumber: data.carNumber,
  mark: data.mark,
  model: data.model
})

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const buildSummaryStep = (prev: VehicleWizardFilesStep): VehicleWizardSummaryStep => ({
  activeStep: SummaryStep,
  data: undefined,
  carNumber: prev.carNumber,
  mark: prev.mark,
  model: prev.model
})

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const createVehicleFormStorage = (name: string, storage: () => Storage) =>
  create<VehicleWizardCommonState & VehicleWizardCommonStateActions>()(
    devtools(
      persist(
        (set) => ({
          ...vehicleWizardCommonStateInitial,
          switchStepToData: (data) => set(() => buildDataStep(data)),
          // TODO: cast is bad!
          switchStepToFiles: (data) => set((state) => buildFilesStep((state as VehicleWizardDataStep).formId, data)),
          // TODO: cast is bad!
          switchStepToSummary: () =>
            set((state) => {
              storage().removeItem(name)
              return buildSummaryStep(state as VehicleWizardFilesStep)
            }),
          reset: () => set(vehicleWizardCommonStateInitial)
        }),
        {
          name,
          storage: createJSONStorage(storage)
        }
      )
    )
  )
