import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { FormId } from '@api/endpoints'
import { FormPhotos, FormType, uploadFilesList } from '@api/endpoints/forms'
import { Button } from '@mui/material'
import { useMutationSendVehiclePhotos, useMutationUploadFile } from '@hooks/ReactQuery/forms'
import { useFormTrackedWrapper } from '@hooks/Tracking'
import { CommonFormProps } from '@controls/types'
import { useZodForm } from '@controls/Form'
import { UploadingModal } from '@controls/modal/UploadingModal'
import { defaultImageDropzoneOptions } from '@controls/file'
import { FormFileUploadManager } from '@controls/Form/FormFileUploadManager'
import { FlexContainer } from '@layout/FlexContainer'
import { VehicleImagesFormSchema, VehicleImagesFormValues } from '@forms/VehiclePhotosForm/types'
import { ActionButtonsContainer } from '@layout/ActionButtonsContainer'
import formStyles from '@assets/css/forms.module.css'
import euBadge from '@assets/images/form/eu-badge.svg'

export type VehiclePhotosFormProps = {
  wizardStep?: number
  formType: FormType
  formId: FormId
  btnSubmitTitle?: string
  model?: string
  plateNumber?: string
  mark?: string
} & Omit<CommonFormProps<FormPhotos>, 'defaultValues'>

export const VehiclePhotosForm: React.FC<VehiclePhotosFormProps> = ({
  wizardStep,
  formType,
  formId,
  btnSubmitTitle,
  model,
  plateNumber,
  mark,
  //defaultValues,
  disabledControls,
  onSubmitSuccess
}) => {
  const { t } = useTranslation()

  const [uploadedFiles, setUploadedFiles] = React.useState(0)
  const [totalFiles, setTotalFiles] = React.useState(0)
  const [formPhotos, setFormPhotos] = React.useState<FormPhotos>()

  //TODO inform the client that the upload failed and offer to try again.
  const uploadFile = useMutationUploadFile({})

  const sendVehiclePhotos = useMutationSendVehiclePhotos(formId, {
    onSuccess: () => {
      if (formPhotos) {
        return onSubmitSuccess(formPhotos)
      }
    }
  })

  const incrementUploadedFiles = (): void => setUploadedFiles((uploadedFiles) => uploadedFiles + 1)
  const uploadFiles = uploadFilesList(uploadFile.mutateAsync, incrementUploadedFiles, incrementUploadedFiles)

  // TODO: Looks like not really needed both conditions
  const isUploadingFiles = uploadedFiles < totalFiles || uploadFile.isPending
  // TODO: It would be better to disable until both operations are completed (or disabled state can flicker between requests)
  const isSubmitting = isUploadingFiles || sendVehiclePhotos.isPending

  const form = useZodForm<VehicleImagesFormValues>({
    schema: VehicleImagesFormSchema,
    defaultValues: {
      imageFiles: []
    }
  })

  const handleSubmitSuccess = async ({ imageFiles }: VehicleImagesFormValues): Promise<void> => {
    if (imageFiles && imageFiles.length > 0) {
      setUploadedFiles(0)
      setTotalFiles(imageFiles.length)
      const imageIds = await uploadFiles(imageFiles).then((files) => files.map((file) => file.fileId))
      const vehiclePhotosForm = { imageIds }
      setFormPhotos(vehiclePhotosForm)
      await sendVehiclePhotos.mutateAsync(vehiclePhotosForm)
    } else {
      const vehiclePhotosForm = { imageIds: [] }
      setFormPhotos(vehiclePhotosForm)
      await sendVehiclePhotos.mutateAsync(vehiclePhotosForm)
    }
  }

  const formSubmitHandler = useFormTrackedWrapper(
    { formName: formType, formStep: wizardStep },
    form,
    handleSubmitSuccess
  )

  return (
    <>
      <div className={formStyles.formStep__success}>
        <div className={formStyles.formStep__carMark}>
          {t('global.forms.success.uploadPhotos')}
          <span className={formStyles.formStep__successBold}>
            {' '}
            {mark}
            {', '}
            {model}
          </span>
        </div>
        <div className={formStyles.plateNumberSmall}>
          <img src={euBadge} alt="eu Badge" loading="lazy" className={formStyles.plateNumberSmall__small} />
          {plateNumber}
        </div>
      </div>
      <UploadingModal open={isUploadingFiles} uploadedFiles={uploadedFiles} totalFiles={totalFiles} />
      <form onSubmit={formSubmitHandler.handleSubmit}>
        <FlexContainer vertical>
          <FlexContainer>
            <FormFileUploadManager
              control={form.control}
              name="imageFiles"
              multiple={true}
              disabled={disabledControls || isSubmitting}
              maxFiles={10}
              options={{
                inputText: t('global.fileUpload.uploadPhoto'),
                inputTextDragActive: t('global.fileUpload.dropToUpload'),
                dropzoneOptions: defaultImageDropzoneOptions
              }}
            />
          </FlexContainer>
          <ActionButtonsContainer className={formStyles.alignRight}>
            <Button
              variant="contained"
              color="secondary"
              type="submit"
              //endIcon={<ArrowForwardIcon />}
              disabled={disabledControls || isSubmitting}
              className={formStyles.nextButton}
            >
              {btnSubmitTitle ? btnSubmitTitle : t('global.buttons.getQuote')}
            </Button>
          </ActionButtonsContainer>
        </FlexContainer>
      </form>
    </>
  )
}
