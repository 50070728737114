import { FormId, VehicleMake, VehicleModel, VehicleRegistrationNumber } from '@api/endpoints'
import { FormPhotos, FormVehicleRequest } from '@api/endpoints/forms'
import { VehicleInitialFormValues } from '@forms/VehicleInitialForm/types'

export const InitialFormStep = 1
export const VehicleFormStep = 2
export const FilesFormStep = 3
export const SummaryStep = 4

export type VehicleMinimalInfo = Readonly<{
  carNumber: VehicleRegistrationNumber
  mark: VehicleMake
  model: VehicleModel
}>

export type RequestInfo = Readonly<{
  formId: FormId
}>

export type VehicleWizardStep<Step extends number, Data extends Record<string, unknown> | void> = Readonly<{
  activeStep: Step
  data: Data
}>

export type VehicleWizardInitialStep<
  Step extends number = typeof InitialFormStep,
  Data extends Partial<VehicleInitialFormValues> = Partial<VehicleInitialFormValues>
> = VehicleWizardStep<Step, Data>

export type VehicleWizardDataStep<
  Step extends number = typeof VehicleFormStep,
  Data extends Partial<FormVehicleRequest> = Partial<FormVehicleRequest>
> = RequestInfo & Omit<VehicleInitialFormValues, 'consent'> & VehicleWizardStep<Step, Data>

export type VehicleWizardFilesStep<
  Step extends number = typeof FilesFormStep,
  Data extends FormPhotos = FormPhotos
> = RequestInfo & VehicleMinimalInfo & VehicleWizardStep<Step, Data>

export type VehicleWizardSummaryStep<Step extends number = typeof SummaryStep> = VehicleMinimalInfo &
  VehicleWizardStep<Step, void>
