import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { FormType, RequestOrigin, VehicleMntResponse } from '@api/endpoints/forms/types'
import { CommonFormProps } from '@controls/types'
import { useZodForm } from '@controls/Form'
import { useLanguage } from '@hooks/useLanguage'
import { useMutationSendInitialVehicleForm } from '@hooks/ReactQuery/forms'
import { VehicleInitialFormSchema, VehicleInitialFormValues } from './types'
import { useFormTrackedWrapper } from '@hooks/Tracking'
import { VehicleInitialFormInputs } from './VehicleInitialFormInputs'
import { captchaKey } from '@constants/configuration'
import { useLocation } from 'react-router-dom'

type VehicleInitialFormResult = Pick<VehicleInitialFormValues, 'carNumber' | 'phoneNumber'> & VehicleMntResponse

export type VehicleInitialFormProps = {
  formType: FormType
  btnSubmitTitle?: string
  wizardStep?: number
  consentChecked?: boolean
} & CommonFormProps<VehicleInitialFormValues, VehicleInitialFormResult>

const formSource: RequestOrigin = 'CARPROF_ORIGIN'

export const VehicleInitialForm: React.FC<VehicleInitialFormProps> = ({
  formType,
  btnSubmitTitle,
  wizardStep,
  defaultValues,
  disabledControls,
  consentChecked = false,
  onSubmitSuccess
}) => {
  const { t } = useTranslation()
  const { language } = useLanguage()
  const location = useLocation()
  // Get page Url query string without the leading '?'. Use navigation hook to get the query string.

  const [submittedData, setSubmittedData] = React.useState<VehicleInitialFormValues | undefined>(undefined)
  const [captchaPending, setCaptchaPending] = React.useState(false)

  const form = useZodForm<VehicleInitialFormValues>({
    schema: VehicleInitialFormSchema,
    defaultValues: { ...defaultValues, consent: consentChecked }
  })

  const handleInvalidPhoneNumber = (): void => {
    form.setError('phoneNumber', { message: t('global.form_errors.invalid_phone_number') })
  }

  const sendInitialVehicleFormRequest = useMutationSendInitialVehicleForm({
    onSuccess: (values) =>
      onSubmitSuccess({ carNumber: submittedData!.carNumber, phoneNumber: submittedData!.phoneNumber, ...values }),
    onInvalidPhoneNumber: handleInvalidPhoneNumber
  })

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleSuccessSubmit = async ({ consent, ...values }: VehicleInitialFormValues): Promise<void> => {
    setSubmittedData(values)
    setCaptchaPending(true)
    // eslint-disable-next-line functional/no-try-statements
    try {
      const captchaToken = await grecaptcha.execute(captchaKey, { action: 'submit' })
      sendInitialVehicleFormRequest.mutate({
        captchaToken,
        formSource,
        source: location.search.slice(1),
        formType,
        language,
        ...values
      })
    } finally {
      setCaptchaPending(false)
    }
  }

  React.useEffect(() => {
    if (defaultValues) {
      form.reset(defaultValues)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValues])

  return (
    <form
      onSubmit={
        useFormTrackedWrapper({ formName: formType, formStep: wizardStep }, form, handleSuccessSubmit).handleSubmit
      }
    >
      <VehicleInitialFormInputs
        btnSubmitTitle={btnSubmitTitle}
        control={form.control}
        disabledControls={disabledControls || sendInitialVehicleFormRequest.isPending || captchaPending}
      />
    </form>
  )
}
