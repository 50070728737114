import * as z from 'zod'
import { FormVehicleInitialRequestSchema } from '@api/endpoints/forms/types'
import { ConsentPersonalDataSchema } from '@forms/types'

export const VehicleInitialFormSchema = FormVehicleInitialRequestSchema.pick({
  carNumber: true,
  phoneNumber: true
}).extend({
  consent: ConsentPersonalDataSchema
})
export type VehicleInitialFormValues = z.infer<typeof VehicleInitialFormSchema>
