import * as React from 'react'
import { FormId, PhoneNumber } from '@api/endpoints'
import { useMutationSendVehicleData } from '@hooks/ReactQuery/forms'
import { FormType, FormVehicleRequest } from '@api/endpoints/forms'
import { useQueryFindVehicleFromMntByRegNumber } from '@hooks/ReactQuery/vehicles'
import { CommonFormProps } from '@controls/types'
import { useZodForm } from '@controls/Form'
import { VehicleFormStep } from '@stores/forms'
import { VehicleDetailsSchema, VehicleDetailsValues } from './types'
import { VehicleDetailsFromInputs } from './VehicleDetailsFromInputs'
import { useFormTrackedWrapper } from '@hooks/Tracking'

export type BuyoutVehicleFormProps = Readonly<{
  formId: FormId
  formType: FormType
  wizardStep: typeof VehicleFormStep
  phoneNumber: PhoneNumber
}> &
  CommonFormProps<FormVehicleRequest>

export const VehicleDetailsForm: React.FC<BuyoutVehicleFormProps> = ({
  formId,
  formType,
  wizardStep,
  phoneNumber,
  disabledControls,
  defaultValues,
  onSubmitSuccess
}) => {
  const sendVehicleData = useMutationSendVehicleData(formId, {
    onSuccess: (data) => {
      void onSubmitSuccess?.(data)
    }
  })

  const form = useZodForm<VehicleDetailsValues>({
    schema: VehicleDetailsSchema,
    defaultValues: defaultValues
  })

  const findVehicleFromMntByRegNumber = useQueryFindVehicleFromMntByRegNumber(form.watch('carNumber'), false)

  const handleSearchClick = React.useCallback(async () => {
    const fetchResult = await findVehicleFromMntByRegNumber.refetch()
    if (fetchResult.data) {
      const data = form.watch()
      form.reset({
        ...data,
        carNumber: fetchResult.data.registrationNumber,
        mark: fetchResult.data.mark,
        model: fetchResult.data.model
      })
    }
  }, [findVehicleFromMntByRegNumber, form])

  const handleSubmitSuccess = React.useCallback(
    (values: FormVehicleRequest): void => {
      sendVehicleData.mutate(values)
    },
    [sendVehicleData]
  )

  React.useEffect(() => {
    if (defaultValues) {
      form.reset(defaultValues)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValues])

  return (
    <form
      onSubmit={
        useFormTrackedWrapper({ formName: formType, formStep: wizardStep }, form, handleSubmitSuccess).handleSubmit
      }
    >
      <VehicleDetailsFromInputs
        control={form.control}
        phoneNumber={phoneNumber}
        disabledControls={disabledControls || findVehicleFromMntByRegNumber.isFetching || sendVehicleData.isPending}
        onSearchClick={handleSearchClick}
      />
    </form>
  )
}
