import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { FN, Handler } from '@digital-magic/ts-common-utils'
import { PhoneNumber } from '@api/endpoints'
import { ControlledFormProps } from '@controls/types'
import { FormTextField } from '@controls/Form'
import { Button, TextField, useMediaQuery } from '@mui/material'
import { ActionButtonsContainer } from '@layout/ActionButtonsContainer'
import { VehicleDetailsValues } from './types'
import formStyles from '@assets/css/forms.module.css'
import euBadge from '@assets/images/form/eu-badge.svg'
import refresh from '@assets/images/form/reload.svg'
import tick from '@assets/icons/tick.svg'
import clsx from 'clsx'
import { useTheme } from '@hooks/useTheme'

export type VehicleDetailsFromProps = Readonly<{
  phoneNumber: PhoneNumber
  btnSubmitTitle?: string
  onSearchClick?: Handler<void> | FN<void, Promise<void>>
}> &
  ControlledFormProps<VehicleDetailsValues>

export const VehicleDetailsFromInputs: React.FC<VehicleDetailsFromProps> = ({
  phoneNumber,
  btnSubmitTitle,
  onSearchClick,
  control,
  disabledControls
}) => {
  const { t } = useTranslation()

  const { currentTheme } = useTheme()
  const isDownSm = useMediaQuery(currentTheme.breakpoints.down('sm'))

  return (
    <>
      <div className={formStyles.formStep__greetings}>
        <img src={tick} alt="refresh" loading="lazy" className={formStyles.formStep__successTick} />
        {t('global.forms.vehicleRegisterSearch.messages.stepTwoTitle')}
      </div>
      <div className={formStyles.formGrid}>
        <div className={formStyles.gridItem}>
          <FormTextField
            control={control}
            name="fullName"
            type="text"
            label={t('global.forms.customer.fields.name')}
            disabled={disabledControls}
            className={formStyles.inputWhiteBg}
          />
        </div>
        <div className={formStyles.gridItem}>
          <FormTextField
            control={control}
            name="email"
            type="email"
            label={t('global.forms.customer.fields.email')}
            disabled={disabledControls}
            className={formStyles.inputWhiteBg}
          />
        </div>
        <div className={formStyles.gridItem}>
          <TextField
            name="phoneNumber"
            label={t('global.forms.customer.fields.phone')}
            value={phoneNumber}
            disabled
            className={formStyles.inputWhiteBg}
          />
        </div>
        <div className={formStyles.gridItem}>
          <FormTextField
            control={control}
            type="text"
            name="mark"
            // TODO: Create a new translation key "global.forms.*"
            label={t('pages.exchange.form.fields.make')}
            disabled={disabledControls}
            className={clsx(formStyles.required, formStyles.inputWhiteBg)}
          />
        </div>
        <div className={formStyles.gridItem}>
          <FormTextField
            control={control}
            type="text"
            name="model"
            // TODO: Create a new translation key "global.forms.*"
            label={t('pages.exchange.form.fields.model')}
            disabled={disabledControls}
            className={clsx(formStyles.required, formStyles.inputWhiteBg)}
          />
        </div>
        <div className={formStyles.gridItem}>
          {onSearchClick && (
            <div className={formStyles.plateNumber__group}>
              <FormTextField
                control={control}
                type="text"
                name="carNumber"
                // TODO: Create a new translation key "global.forms.*"
                label={t('pages.exchange.form.fields.enter_reg_number')}
                disabled={disabledControls}
                className={formStyles.plateNumber}
              />
              <Button
                variant="contained"
                color="white"
                type="button"
                disabled={disabledControls}
                onClick={() => onSearchClick()}
                className={formStyles.refreshButton}
              >
                <img src={refresh} alt="refresh" loading="lazy" className={formStyles.refreshButton__image} />
              </Button>
              <div className={formStyles.plateNumber__badge}>
                <img src={euBadge} alt="eu Badge" loading="lazy" className={formStyles.plateNumber__image} />
              </div>
            </div>
          )}
        </div>
        <div className={formStyles.gridItem}>
          <FormTextField
            control={control}
            type="number"
            name="mileage"
            // TODO: Create a new translation key "global.forms.*"
            label={t('pages.exchange.form.fields.mileage')}
            disabled={disabledControls}
            className={formStyles.inputWhiteBg}
          />
        </div>
        <div className={formStyles.gridItem}>
          <FormTextField
            control={control}
            name="location"
            // TODO: Create a new translation key "global.forms.*"
            label={t('pages.exchange.form.fields.location')}
            disabled={disabledControls}
            className={formStyles.inputWhiteBg}
          />
        </div>
        <div className={formStyles.gridItem}>
          <FormTextField
            control={control}
            type="number"
            name="requestedPrice"
            // TODO: Create a new translation key "global.forms.*"
            label={t('pages.exchange.form.fields.desired_price')}
            disabled={disabledControls}
            className={clsx(formStyles.required, formStyles.inputWhiteBg)}
          />
        </div>
        <div className={formStyles.gridItemLarge}>
          <FormTextField
            control={control}
            name="additionalInfo"
            label={t('global.forms.customer.fields.additional_info')}
            multiline
            rows={isDownSm ? 2 : 4}
            disabled={disabledControls}
            className={formStyles.inputWhiteBg}
          />
        </div>
      </div>
      <ActionButtonsContainer className={formStyles.alignRight}>
        <Button
          variant="contained"
          color="secondary"
          type="submit"
          //endIcon={<ArrowForwardIcon />}
          disabled={disabledControls}
          className={formStyles.nextButton}
        >
          {btnSubmitTitle ? btnSubmitTitle : t('global.buttons.getQuote')}
        </Button>
      </ActionButtonsContainer>
    </>
  )
}
