import * as React from 'react'
import { UseMutationResult, useMutation } from '@tanstack/react-query'
import { FormPhotos, postFormImages } from '@api/endpoints/forms'
import { QueryErrorHandler, useDefaultQueryErrorHandlerPublic, UseMutationProps } from '@hooks/ReactQuery'
import { FormId } from '@api/endpoints'

export const useMutationSendVehiclePhotos = (
  formId: FormId,
  { onSuccess }: UseMutationProps
): UseMutationResult<void, unknown, FormPhotos> => {
  const defaultErrorHandler = useDefaultQueryErrorHandlerPublic()

  const mutation = useMutation({
    mutationFn: (data: FormPhotos) => postFormImages(formId, data)
  })

  const errorHandler: QueryErrorHandler = (e) => {
    defaultErrorHandler(e)
  }

  React.useEffect(() => {
    if (mutation.error) {
      errorHandler(mutation.error)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mutation.error])

  React.useEffect(() => {
    if (mutation.isSuccess) {
      void onSuccess?.()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mutation.isSuccess])

  return mutation
}
