import * as React from 'react'
import { UseMutationResult, useMutation } from '@tanstack/react-query'
import { FormVehicleRequest, postFormData } from '@api/endpoints/forms'
import { QueryErrorHandler, useDefaultQueryErrorHandlerPublic, UseMutationProps } from '@hooks/ReactQuery'
import { FormId } from '@api/endpoints'

export const useMutationSendVehicleData = (
  formId: FormId,
  { onSuccess }: UseMutationProps<FormVehicleRequest>
): UseMutationResult<FormVehicleRequest, unknown, FormVehicleRequest> => {
  const defaultErrorHandler = useDefaultQueryErrorHandlerPublic()

  const mutation = useMutation({
    mutationFn: (data: FormVehicleRequest) => postFormData(formId, data).then(() => data)
  })

  const errorHandler: QueryErrorHandler = (e) => {
    defaultErrorHandler(e)
  }

  React.useEffect(() => {
    if (mutation.error) {
      errorHandler(mutation.error)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mutation.error])

  React.useEffect(() => {
    if (mutation.isSuccess) {
      void onSuccess?.(mutation.data)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mutation.isSuccess])

  return mutation
}
